<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Permission: {{ typeof permission.name === 'object' ? (permission.name[locale.code] ? permission.name[locale.code] : '[no translation for element with ID: '+permission.id+']') : permission.name }} <span v-if="!permission.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="permission" title="Permissions" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        permission: Object,
    },
}
</script>

<style scoped>

</style>
