import AppLayout from "@/Pages/Projectbuilder/AppLayout"
import Main from "@/Pages/Projectbuilder/Main"
import {usePage} from "@inertiajs/inertia-vue3"
import Breadcrumb from "@/Pages/Projectbuilder/Helpers/Breadcrumb"

export default {
    name: "Show",
    props: {
        page: Object,
        element: Object,
        title: String,
        nameField: {
            type: String,
            default: 'name'
        },
        enableName: {
            type: Boolean,
            default: true
        }
    },
    components: {
        AppLayout,
        Main,
        Breadcrumb,
    },
    data() {
        return {
            locale: usePage().props.value.locale,
            breadcrumb: usePage().props.value.shared.breadcrumb,
        }
    },
}
