<template>
    <Main>
        <slot>
            <div class="p-12 sm:px-20 bg-white border-b border-gray-200">
                <div v-if="typeof breadcrumb === 'object' && Object.keys(breadcrumb).length !== 0">
                    <Breadcrumb :breadcrumb="breadcrumb" />
                </div>
                <h2>
                    {{ element.id }}
                    {{ enableName ? ('. ' + typeof element[nameField] === 'object' ? (element[nameField][locale.code] ? element[nameField][locale.code] : '[no translation for element with ID: '+element.id+']') : element[nameField]) : '' }}
                    <span v-if="enableName && !element[nameField][locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
                </h2>
            </div>
        </slot>
    </Main>
</template>

<script>
import PbShow from "Pub/js/Projectbuilder/pbshow"

export default {
    extends: PbShow,
    name: "Show",
    props: {
        nameField: {
            type: String,
            default: 'name'
        },
        enableName: {
            type: Boolean,
            default: true
        }
    },
}
</script>

<style scoped>

</style>
