<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Provider: {{ typeof provider.name === 'object' ? (provider.name[locale.code] ? provider.name[locale.code] : '[no translation for element with ID: '+provider.id+']') : provider.name }} <span v-if="!provider.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="provider" title="Providers" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        provider: Object,
    },
}
</script>

<style scoped>

</style>
