import Swal from "sweetalert2";
import {TableFields} from "Pub/js/Projectbuilder/Helpers/tablefields"
import Button from "@/Jetstream/Button"
import SecondaryButton from "@/Jetstream/SecondaryButton"
import AppLayout from "@/Pages/Projectbuilder/AppLayout"
import Main from "@/Pages/Projectbuilder/Main"
import {usePage} from "@inertiajs/inertia-vue3";
import Table from "@/Pages/Projectbuilder/Helpers/CRUD/Table"
import Form from "@/Pages/Projectbuilder/Helpers/CRUD/Form"
import Breadcrumb from "@/Pages/Projectbuilder/Helpers/Breadcrumb"

export default {
    name: "Index",
    props: {
        elements: Object,
        title: String,
        breadcrumb: Object,
    },
    components: {
        Button,
        SecondaryButton,
        AppLayout,
        Main,
        Table,
        Form,
        Breadcrumb,
    },
    data() {
        return {
            allowed: usePage().props.value.shared.allowed,
            sort: usePage().props.value.shared.sort,
            showpos: usePage().props.value.shared.showpos,
            showid: usePage().props.value.shared.showid,
            model: usePage().props.value.shared.model,
            defaults: usePage().props.value.shared.defaults,
            required: usePage().props.value.shared.required,
            listing: usePage().props.value.shared.listing,
            resetable: usePage().props.value.shared.resetable,
            showcreate: usePage().props.value.shared.showcreate,
            hiddenid: 0,
        }
    },
    methods: {
        loadForm() {
            let swalConfig = TableFields.buildSwalLoadFormConfig({text: "Create", formitem: this.title})
            swalConfig['didOpen'] = () => {
                TableFields.appendToSwal(this.hiddenid)
            }
            swalConfig['willClose'] = () => {
                TableFields.removeFromSwal(this.hiddenid)
            }
            Swal.fire(swalConfig).then(
                () => {
                    if (usePage().props.value.shared.debug_enabled) {
                        console.log(
                            "[ProjectBuilder] DEBUG" + "\n" +
                            "Swal: Form - After firing" + "\n" +
                            "Component: PbIndex"
                        )
                    }
                }
            );
        },
        getRowPos(el) {
            return TableFields.getRowPos(this.sort, el)
        },
        getTablePositions(group) {
            let sort = [];
            document.querySelectorAll('#'+this.model+'-table-rows tr').forEach(function(value){
                if (value.dataset.group === group) {
                    sort.push(value.dataset.id)
                }
            })
            return sort
        },
        resetFilters() {
            window.location.href = window.location.href.split('?')[0]
        },
        getFilteredStatus() {
            let url = new URL(window.location.href).searchParams;
            return url.has('filter_key') && url.has('filter_val')
        }
    },
    computed: {
        buildHiddenIdTag() {
            this.hiddenid = TableFields.buildHiddenIdTag()
            return this.hiddenid
        }
    },
}
