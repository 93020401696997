<template>
    <div class="py-12">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import "Pub/css/Projectbuilder/projectbuilder.css"

export default {
    name: "Main"
}
</script>

<style scoped>

</style>
