import Edit from "@/Pages/Projectbuilder/Helpers/CRUD/Edit"
import layout from "./layout"
import {usePage} from "@inertiajs/inertia-vue3";

export default {
    extends: layout,
    components: {
        Edit,
    },
    data() {
        return {
            locale: usePage().props.value.locale,
        }
    },
}
