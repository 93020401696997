<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Logger: {{ typeof logger.name === 'object' ? (logger.name[locale.code] ? logger.name[locale.code] : '[no translation for element with ID: '+logger.id+']') : logger.name }} <span v-if="!logger.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="logger" title="Loggers" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        logger: Object,
    },
}
</script>

<style scoped>

</style>
