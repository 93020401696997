<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Config: {{ typeof config.name === 'object' ? (config.name[locale.code] ? config.name[locale.code] : '[no translation for element with ID: '+config.id+']') : config.name }} <span v-if="!config.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="config" title="Configs" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        config: Object,
    },
}
</script>

<style scoped>

</style>
