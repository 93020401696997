<template>
    <tbody class="bg-gray-200">
        <slot></slot>
    </tbody>
</template>

<script>
export default {
    name: "Body"
}
</script>

<style scoped>

</style>
