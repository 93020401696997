<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Product: {{ typeof product.name === 'object' ? (product.name[locale.code] ? product.name[locale.code] : '[no translation for element with ID: '+product.id+']') : product.name }} <span v-if="!product.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="product" title="Products" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        product: Object,
    },
}
</script>

<style scoped>

</style>
