import Button from "@/Jetstream/Button"
import { Inertia } from '@inertiajs/inertia'
import Swal from "sweetalert2"
import SimpleInput from "@/Pages/Projectbuilder/Forms/SimpleInput"
import {usePage} from "@inertiajs/inertia-vue3";
import {Helpers} from "Pub/js/Projectbuilder/Helpers/helpers"
import {reactive} from "vue"
import axios from "axios"

export default {
    name: "Form",
    props: {
        data: Object,
        keyid: String,
        defaults: Object,
        required: Object,
        title: String,
    },
    components: {
        Button,
        SimpleInput,
    },
    data() {
        return {
            formconfig: usePage().props.value.shared.formconfig,
            directory: Helpers.toLowerCaseRoute(this.title),
            buttontext: (this.data.item ? "Save" : "Create"),
            form: reactive(this.buildForm(usePage().props.value.shared.formconfig)),
            locale: usePage().props.value.locale,
        }
    },
    methods: {
        disableReadonly(event) {
            document.getElementById(event.target.id).readOnly = false
        },
        isRequired(key) {
            return (this.required ? this.required.includes(key) : false)
        },
        isEmptyField(value) {
            return !value
        },
        updateValue(value, key) {
            if (value.hasOwnProperty('dependant') && value.dependant && value.dependant.config.hasOwnProperty('relation')) {
                this.form[key] = value.value
                axios.
                    get(Helpers.buildRoute(value.dependant.config.route)+'/page/1/9999999999', {
                        params: {
                            'filter_key': value.dependant.config.relation.field,
                            'filter_val': value.dependant.config.hasOwnProperty('value_field') && value.dependant.list && (value.dependant.list.length > 0) ?
                                value.dependant.list[0][value.dependant.config.value_field] :
                                value.dependant.value,
                            'returnJson': true,
                        },
                    })
                    .then((res) => {
                        let select = document.getElementById('grid-'+ value.dependant.config.field +'-' + this.keyid)
                        // Remove existing options
                        select.options.length = 0
                        // Get new options.
                        let options = res.data.data[value.dependant.config.key].data.map((el) => {
                            return {
                                'value': el[value.dependant.config.relation.value_field],
                                'text': ((typeof el[value.dependant.config.relation.text] === 'object') ?
                                    (el[value.dependant.config.relation.text][this.locale.code] ? el[value.dependant.config.relation.text][this.locale.code] : '[no translation for '+value.dependant.config.field+' ID: '+el[value.dependant.config.relation.value_field]+'] ['+this.locale.code+']') :
                                    el[value.dependant.config.relation.text]),
                                'iso': el[value.dependant.config.relation.text].hasOwnProperty('code') ? el[value.dependant.config.relation.text].code : ''
                            }
                        })
                        // Add new options
                        Promise.all(
                            options.map((optionData, index) => {
                                let opt = document.createElement('option')
                                opt.appendChild(document.createTextNode(optionData.text));
                                opt.value = optionData.value
                                select.appendChild(opt);
                            })
                        ).then(() => {
                            // Update select value (reset)
                            select.value = 0
                            this.updateValue({value: 0}, value.dependant.config.field)
                        })
                    })
            } else {
                this.form[key] = value.value
            }
            if (usePage().props.value.shared.debug_enabled) {
                console.log(
                    "[ProjectBuilder] DEBUG" + "\n" +
                    "After form updated" + "\n" +
                    "Component: UserForm"
                )
                console.log(this.form)
            }
        },
        buildForm(fields) {
            const inputs = {}
            for (const [index, field] of Object.entries(fields)) {
                let key = field.hasOwnProperty('data_field') ? field.data_field : index
                switch(field.type) {
                    case 'text':
                    case 'float':
                    case 'integer':
                    case 'textarea':
                    case 'hidden':
                        inputs[index] = this.data[key] ? this.data[key] : (this.defaults[key] ? this.defaults[key] : '')
                        break;
                    case 'select':
                        inputs[index] = this.data[key] ?
                            (this.data[key].hasOwnProperty('id') ? this.data[key].id : this.data[key]) :
                            (this.defaults.hasOwnProperty(key) ?
                                (this.defaults[key].hasOwnProperty('id') ? this.defaults[key].id : this.defaults[key]) :
                                0)
                        break;
                    case 'select-multiple':
                        inputs[index] = Helpers.getModelIdsList(this.data[key])
                        break;
                    case 'password':
                        inputs[index] = ''
                        break;
                    case 'file':
                        inputs[index] = null
                        break;
                    default:
                        break;
                }
            }
            return inputs
        },
    },
    computed: {
        readonly() {
            return this.data.hasOwnProperty('item')
        },
        submit() {
            if (this.data.hasOwnProperty('item')) {
                Inertia.put("/"+this.directory+"/"+ this.data.item, this.form, {
                    preserveScroll: true,
                    onSuccess: () => Swal.close()
                })
            } else {
                Inertia.post("/"+this.directory, this.form, {
                    preserveScroll: true,
                    preserveState: false,
                    onSuccess: () => Swal.close()
                })
            }
        }
    },
}
