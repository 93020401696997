<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Provider: {{ typeof provider.name === 'object' ? (provider.name[locale.code] ? provider.name[locale.code] : '[no translation for element with ID: '+provider.id+']') : provider.name }} <span v-if="!provider.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="provider" title="Providers" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        provider: Object,
    },
}
</script>

<style scoped>

</style>
