import AppLayout from "@/Pages/Projectbuilder/AppLayout"
import Main from "@/Pages/Projectbuilder/Main"
import Form from "@/Pages/Projectbuilder/Helpers/CRUD/Form"
import {usePage} from "@inertiajs/inertia-vue3"
import Breadcrumb from "@/Pages/Projectbuilder/Helpers/Breadcrumb"

export default {
    name: "Edit",
    props: {
        page: Object,
        element: Object,
        title: String,
        breadcrumb: Object,
    },
    components: {
        AppLayout,
        Main,
        Form,
        Breadcrumb,
    },
    data() {
        return {
            defaults: usePage().props.value.shared.defaults,
            required: usePage().props.value.shared.required,
            locale: usePage().props.value.locale,
        }
    },
    methods: {
        setItem(element) {
            element.item = element.id
            return element
        },
    },
}
