<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Customer: {{ typeof customer.name === 'object' ? (customer.name[locale.code] ? customer.name[locale.code] : '[no translation for element with ID: '+customer.id+']') : customer.name }} <span v-if="!customer.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="customer" title="Customers" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        customer: Object,
    },
}
</script>

<style scoped>

</style>
