<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Category: {{ typeof category.name === 'object' ? (category.name[locale.code] ? category.name[locale.code] : '[no translation for element with ID: '+category.id+']') : category.name }} <span v-if="!category.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="category" title="Categories" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        category: Object,
    },
}
</script>

<style scoped>

</style>
