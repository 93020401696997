<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Order Products {{ filteredData.key.length > 0 ? 'for Order #' + filteredData.val : '' }}
            </h2>
        </template>

        <Index :elements="orderproducts" title="Order Products" />
    </AppLayout>
</template>

<script>
import index from "Pub/js/Projectbuilder/Model/index"

export default {
    extends: index,
    props: {
        orderproducts: Object,
    },
}
</script>
