<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Attribute Group: {{ typeof attributegroup.name === 'object' ? (attributegroup.name[locale.code] ? attributegroup.name[locale.code] : '[no translation for element with ID: '+attributegroup.id+']') : attributegroup.name }} <span v-if="!attributegroup.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="attributegroup" title="Attribute Groups" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        attributegroup: Object,
    },
}
</script>

<style scoped>

</style>
