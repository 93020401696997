<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                File: {{ typeof file.name === 'object' ? (file.name[locale.code] ? file.name[locale.code] : '[no translation for element with ID: '+file.id+']') : file.name }} <span v-if="!file.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="file" title="Files" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        file: Object,
    },
}
</script>

<style scoped>

</style>
