<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Attribute Group: {{ typeof attributegroup.name === 'object' ? (attributegroup.name[locale.code] ? attributegroup.name[locale.code] : '[no translation for element with ID: '+attributegroup.id+']') : attributegroup.name }} <span v-if="!attributegroup.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="attributegroup" title="Attribute Groups" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        attributegroup: Object,
    },
}
</script>

<style scoped>

</style>
