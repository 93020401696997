<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Role: {{ typeof role.name === 'object' ? (role.name[locale.code] ? role.name[locale.code] : '[no translation for element with ID: '+role.id+']') : role.name }} <span v-if="!role.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="role" title="Roles" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        role: Object,
    },
}
</script>

<style scoped>

</style>
