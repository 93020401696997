<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Order Product: {{ orderproduct.id }}
            </h2>
        </template>

        <Show :element="orderproduct" :enableName="false" title="Order Product" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        orderproduct: Object,
    },
}
</script>

<style scoped>

</style>
