<template>
    <tfoot class="justify-between">
      <slot></slot>
    </tfoot>
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>

</style>
