<template>
    <input
        :value="value"
        :id="'grid-'+ keyel +'-' + keyid"
        type="number"
        :step="type === 'float' ? '0.01' : '1'"
        min="0"
        :placeholder="type === 'float' ? '0.00' : '0'"
        class="temp-readonly appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 focus:border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        readonly
        :required="isRequired(keyel)"
        @mouseover="disableReadonly"
        @focus="disableReadonly"
        @input="emitInputValue"
    >
</template>

<script>
import PbInput from "Pub/js/Projectbuilder/pbinput"

export default {
    extends: PbInput,
    name: "Number",
}
</script>

<style scoped>

</style>
