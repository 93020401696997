<template>
    <div class="mb-4">
        <div class="text-left" :id="'grid-'+ keyel +'-' + keyid" v-for="(el, k) in list">
            <input
                v-if="!readonly"
                :value="list[k].id"
                type="checkbox"
                :id="'checkbox-'+ keyel +'-' + keyid + '-' + list[k].id"
                class="appearance-none mx-4 px-4 py-3 mb-1 rounded bg-gray-200 text-gray-700 border border-gray-200 focus:outline-none focus:bg-white focus:border-gray-500"
                :checked="isChecked(value, list[k].id)"
                @click="emitCheckboxValue"
            >
            <input
                v-if="readonly"
                type="checkbox"
                class="appearance-none mx-4 px-4 py-3 mb-1 rounded bg-gray-200 text-gray-700 border border-gray-200 focus:outline-none focus:bg-white focus:border-gray-500"
                :checked="isChecked(value, list[k].id)"
                disabled="disabled"
            >
            <input
                v-if="readonly && isChecked(value, list[k].id)"
                :value="list[k].id"
                :id="'checkbox-'+ keyel +'-' + keyid + '-' + list[k].id"
                type="hidden"
                @input="emitInputValue"
            >
            <span>
                {{ list[k].hasOwnProperty('alias') ?
                    ((typeof list[k].alias === 'object') ?
                        (list[k].alias[locale.code] ? list[k].alias[locale.code] : '[no translation for '+keyel+' ID: '+list[k].id+'] ['+locale.code+']') :
                        list[k].alias
                    ) :
                    ((typeof list[k]['name'] === 'object') ?
                        (list[k]['name'][locale.code] ? list[k]['name'][locale.code] : '[no translation for '+keyel+' ID: '+list[k].id+'] ['+locale.code+']') :
                        list[k]['name']
                    )
                }}
            </span>
        </div>
    </div>
</template>

<script>
import PbInput from "Pub/js/Projectbuilder/pbinput"

export default {
    extends: PbInput,
    name: "Checkbox",
}
</script>

<style scoped>

</style>
