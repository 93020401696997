<template>
    <table class="min-w-full leading-normal table-auto text-sm mt-5">
        <slot></slot>
    </table>
</template>

<script>
export default {
    name: "Container"
}
</script>

<style scoped>

</style>
