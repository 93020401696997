import Index from "@/Pages/Projectbuilder/Helpers/CRUD/Index"
import layout from "./layout"

export default {
    extends: layout,
    components: {
        Index,
    },
    data() {
        return {
            filteredData: this.getFilteredData(),
        }
    },
    methods: {
        getFilteredData() {
            let url = new URL(window.location.href).searchParams;
            return {
                key: url.get('filter_key'),
                val: url.get('filter_val'),
            }
        }
    }
}
