<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Attribute: {{ typeof attribute.name === 'object' ? (attribute.name[locale.code] ? attribute.name[locale.code] : '[no translation for element with ID: '+attribute.id+']') : attribute.name }} <span v-if="!attribute.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="attribute" title="Attributes" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        attribute: Object,
    },
}
</script>

<style scoped>

</style>
