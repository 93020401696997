<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Stockproducts
            </h2>
        </template>

        <Index :elements="stockproducts" title="Stockproducts" />
    </AppLayout>
</template>

<script>
import index from "Pub/js/Projectbuilder/Model/index"

export default {
    extends: index,
    props: {
        stockproducts: Object,
    },
}
</script>
