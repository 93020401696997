<template>
    <div :class="ccl ?? ''">
        <button type="submit" :class="cl ?? 'block w-full px-4 py-2 text-sm leading-5 text-gray-700 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition'" v-if="as == 'button'">
            <slot></slot>
        </button>

        <a :href="href" :class="cl ?? 'block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition'" v-else-if="as =='a'" :target="tgt">
            <slot></slot>
        </a>

        <Link :href="href" :class="cl ?? 'block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition'" v-else>
            <slot></slot>
        </Link>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/inertia-vue3';

export default defineComponent({
    components: {
        Link,
    },
    props: ['href', 'as', 'target', 'cl', 'ccl'],
    data() {
        return {
            tgt: this.target ?? '_self',
        }
    },
})
</script>
