<template>
    <div class="w-full flex justify-end" id="breadcrumb">
        <div v-for="(item, i) in breadcrumb">
            <span v-if="i > 0" class="text-gray-500 px-3"> > </span>
            <NavLink
                :href="item.hasOwnProperty('route') && item.route ? (item.hasOwnProperty('id') ? route(item.route, item.id) : route(item.route)) : null"
                type="button"
            >
                <slot>{{ item.text }}</slot>
            </NavLink>
        </div>
    </div>
</template>

<script>
import NavLink from "@/Jetstream/NavLink"

export default {
    name: "Breadcrumb",
    props: {
        breadcrumb: Object,
    },
    components: {
        NavLink,
    },
}
</script>

<style scoped>

</style>
