<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Stockproduct: {{ typeof stockproduct.name === 'object' ? (stockproduct.name[locale.code] ? stockproduct.name[locale.code] : '[no translation for element with ID: '+stockproduct.id+']') : stockproduct.name }} <span v-if="!stockproduct.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Show :element="stockproduct" title="Stockproducts" />
    </AppLayout>
</template>

<script>
import show from "Pub/js/Projectbuilder/Model/show"

export default {
    extends: show,
    props: {
        stockproduct: Object,
    },
}
</script>

<style scoped>

</style>
