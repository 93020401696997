<template>
    <thead class="justify-between">
        <slot></slot>
    </thead>
</template>

<script>
export default {
    name: "Header"
}
</script>

<style scoped>

</style>
