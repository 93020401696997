<template>
    <AppLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Edit Variation: {{ typeof variation.name === 'object' ? (variation.name[locale.code] ? variation.name[locale.code] : '[no translation for element with ID: '+variation.id+']') : variation.name }} <span v-if="!variation.name[locale.code] && locale.country" :class="'fi fi-'+locale.country.code"></span>
            </h2>
        </template>

        <Edit :element="variation" title="Variations" />
    </AppLayout>
</template>

<script>
import edit from "Pub/js/Projectbuilder/Model/edit"

export default {
    extends: edit,
    props: {
        variation: Object,
    },
}
</script>

<style scoped>

</style>
